<template>
  <div>
    <v-breadcrumbs :items="crumbs">
      <template v-slot:divider>
        <v-icon>mdi-chevron-right</v-icon>
      </template>
    </v-breadcrumbs>
    <v-container>
      <v-card>
        <v-card-subtitle>PIN CODE: {{ pin }}</v-card-subtitle>
        <v-card-subtitle>{{ duration }}</v-card-subtitle>
        <v-list>
          <v-list-item-group>
            <div v-for="(item, i) in items" :key="`${item.gid}-${i}`">
              <v-list-item @click="jump(item.gid)">
                <v-list-item-icon v-if="item.state === 'reserved'">
                  <v-icon color="grey">mdi-check-circle-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-icon v-if="item.state === 'confirmed'">
                  <v-icon color="green">mdi-check-circle</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  {{ date(item.start) }} {{ item.detail }}
                </v-list-item-content>
              </v-list-item>
              <v-divider></v-divider>
            </div>
          </v-list-item-group>
        </v-list>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import AxiosEvents from '../services/AxiosEvents'
import moment from 'moment'
export default {
  props: {
    floor: {
      type: String,
    },
  },
  computed: {
    duration() {
      const now = moment()
      const end = moment().add(89, 'd')
      return `${now.format('YYYY/MM/DD')} ~ ${end.format('YYYY/MM/DD')}`
    },
  },
  name: 'Top',
  async mounted() {
    if (localStorage.token) {
      const token = localStorage.token
      this.crumbs.push({ text: this.floor, disabled: true })
      try {
        const { data } = await AxiosEvents.getTops(token, this.floor)
        data.sort((a, b) => {
          if (moment(a.start).isBefore(moment(b.start))) return -1
          if (moment(a.start).isAfter(moment(b.start))) return 1
          return 0
        })
        this.items = data
      } catch (err) {
        console.log(err)
      }
      try {
        const { data } = await AxiosEvents.getPin(token, this.floor)
        this.pin = data.pin
      } catch (err) {
        console.log(err)
      }
    }
  },
  methods: {
    jump: function (gid) {
      this.$router.push(`/reservation/${this.floor}/${gid}`)
    },
    date: function (d) {
      return moment(d).format('MM/DD')
    },
  },
  data: () => ({
    pin: '',
    on: true,
    items: [],
    crumbs: [{ text: 'top', disabled: false, to: { name: 'top' } }],
  }),
}
</script>
